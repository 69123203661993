import { Text } from 'src/components/typo';
import css from './styles.module.scss';
import Button from '../shared/Button';
import Img from '../shared/Image/Img';
import arrowClockwise from 'public/assets/icons/arrowClockwise.svg'
import { errorDetails } from './constants';

const TokenErrors = ({ error, validateToken }) => {
    const details = errorDetails[error] || errorDetails['DEFAULT'];
    const { title, message, buttonText, email } = details;

    return (
        <div className={css.notFound}>
            <Text className={css.notFound__title}>{title}</Text>
            <Text className={css.notFound__message}>{message}</Text>
            {email ? (
                <Button type="button" theme="white" href={`mailto:${email}`}>
                    {buttonText}
                </Button>
            ) : (
                (error === 'ERR_TOKEN_NOT_FOUND') && (
                    <Button
                        type="button"
                        tagName="button"
                        onClick={(e) => {
                            e.preventDefault();
                            validateToken();
                        }}
                        className={css.retryButton}
                        theme="blue-500"
                    >
                        <Img src={arrowClockwise} alt="Retry Icon" width={20} height={20} />
                        {buttonText}
                    </Button>
                )
            )}
        </div>
    );
};

export default TokenErrors;