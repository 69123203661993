import React, { useEffect, useState } from 'react'
import css from '../licenseKeyPage.module.scss'
import Img from "next/legacy/image"
import { Text } from 'src/components/typo'
import classNames from 'classnames/bind'
import Link from 'src/components/shared/Link'
import downloadIcon from 'public/assets/icons/download.svg'
import duplicateIcon from 'public/assets/icons/duplicate.svg'
import { useRouter } from 'next/router'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Button from 'src/components/shared/Button'
import TokenErrors from 'src/components/tokenErrors'
import Separator from 'src/components/shared/Separator'

const cx = classNames.bind(css)

const GetLicense = () => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [licenseKey, setLicenseKey] = useState('')
    const [copySuccess, setCopySuccess] = useState(false)
    const [error, setError] = useState(null)
    const [isLicenseLoading, setIsLicenseLoading] = useState(true)
    const router = useRouter()

    const fetchLicenseKey = async () => {
        const { token } = router.query
        if (!token) {
            setError('ERR_TOKEN_NOT_FOUND')
            setIsLicenseLoading(false)
            return
        }

        const apiEndpoint = `${process.env.NEXT_PUBLIC_LICENSE_REGISTRY_API_URL}/api/get-license?token=${token}`
        setError(null)
        setIsLicenseLoading(true)

        try {
            const response = await fetch(apiEndpoint)
            const result = await response.json()

            if (response.status !== 200) {
                setError(result.error.message)
            }

            setLicenseKey(result.license)
        } catch (error) {
            console.error('Error while fetching license key:', error)
        } finally {
            setIsLicenseLoading(false)
        }
    }

    useEffect(() => {
        fetchLicenseKey();
    }, [router.query])

    const toggleText = () => {
        setIsExpanded(!isExpanded)
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(licenseKey).then(() => {
            setCopySuccess(true)
            setTimeout(() => setCopySuccess(false), 2000)
        })
    }

    const handleDownload = () => {
        const element = document.createElement("a")
        const file = new Blob([licenseKey], { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = "license.txt"
        element.click()
    }

    if (error) {
        return <TokenErrors error={error} validateToken={fetchLicenseKey} />
    }

    return (
        <div className={css.container}>
            <div className={css.header}>
                <Link as="/" href="/">
                    <Img
                        alt="Strapi Logo"
                        src="/assets/strapi-logo-dark.svg"
                        width={152}
                        height={37}
                    />
                </Link>
            </div>
            <div>
                <Text theme="blue-900" className={css.title}>Thank you for choosing the Strapi Growth Plan!</Text>
                <Text theme="neutral-600" className={css.subtitle}>Please find your license below.</Text>
            </div>
            <div className={css.content}>
                <div className={css.content__container}>
                    <div className={css.licenseKey}>
                        <Text theme="grey-600" className={css.licenseKey__title}>License</Text>
                        {isLicenseLoading ? (
                            <Skeleton count={3} style={{ marginBottom: '10px' }} />
                        ) : (
                            <Text theme="blue-900" className={`${css.licenseKey__text} ${isExpanded && css.expanded}`} >
                                {licenseKey}
                            </Text>
                        )}
                        <div className={css.buttons}>
                            <Button className={css.toggleButton} theme="white" onClick={(e) => { e.preventDefault(); toggleText() }} disabled={isLicenseLoading}>
                                {isExpanded ? 'Show Less' : 'Show More'}
                            </Button>
                            <div className={css.buttons__right}>
                                <Button className={css.downloadButton} theme="white" onClick={(e) => { e.preventDefault(); handleDownload() }} disabled={isLicenseLoading}>
                                    <Img src={downloadIcon} alt="downloadIcon" width={16} height={16} className={css.buttonIcon} />
                                    Download
                                </Button>
                                <Button className={css.copyButton} theme="white" onClick={(e) => { e.preventDefault(); handleCopy() }} disabled={isLicenseLoading}>
                                    <Img src={duplicateIcon} alt="duplicateIcon" width={16} height={16} className={css.buttonIcon} />
                                    {copySuccess ? 'Copied!' : 'Copy'}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className={css.agreement}>
                        <Text theme="neutral-600" className={css.agreement__text}>
                            By using the license key provided to access the Strapi software or renewing your subscription, you confirm your agreement to the terms and conditions outlined in the Strapi Enterprise Edition Software License Agreement.
                        </Text>
                        <Text theme="neutral-600" className={css.agreement__text}>You can review the full agreement <Link href="https://strapi.io/enterprise-terms" target="_blank">here</Link>. These terms apply from the subscription Effective Date and govern your use of the Strapi software.</Text>
                    </div>
                </div>
                <div className={css.info}>
                    <Text className={css.info__title}>What’s next?</Text>
                    <ol className={cx(css.list, css.info__text)}>
                        <li>Download the file or copy-paste the license</li>
                        <li>Move the <span className={css.menloFont}>license.txt</span> file at the root of your Strapi project or add the <span className={cx(css.menloFont, css.strapiLicense)}>STRAPI_LICENSE=&lt;your_license&gt;</span> in your .env file.</li>
                        <li>Start your project</li>
                    </ol>
                    <Separator />
                    <Text className={css.info__title}>Manage subscription</Text>
                    <Text theme="blue-900" className={css.info__text}>Click <Link href={process.env.NEXT_PUBLIC_CHARGEBEE_PORTAL}>here</Link> to manage or modify your subscription.</Text>
                </div>
            </div>
        </div>
    )
}

export default GetLicense;
