import React from 'react'

import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const Separator = ({ className, forwardRef }) => {
  return <div ref={forwardRef} className={cx(className, css.Separator)} />
}

Separator.defaultProps = {}

export default Separator
