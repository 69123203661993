import { GROWTH_PLAN_SSO_PRICE_ID } from "src/components/shared/PlanDetailsCard/Constants";

export const formatPrice = (price) => {
    return (price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

export const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };

export const formatDate = (dateString, options) => {
    return new Date(dateString).toLocaleDateString('en-US', options);
};

export const nextChargeDate = (dateString) => {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + 1);
    return date.toLocaleDateString('en-US', optionsDate);
};

export const getSeats = (amount, planId) => {
    if (planId === GROWTH_PLAN_SSO_PRICE_ID) {
        return Math.ceil(amount / 6500, 2)
    }
    return Math.ceil(amount / 1500, 2);
}

export const decodeEmail = (email) => {
    return decodeURIComponent(email);
};
