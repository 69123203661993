
import css from '../licenseKeyPage.module.scss'
import Img from "next/legacy/image"
import { Text } from 'src/components/typo'
import Button from 'src/components/shared/Button'
import Separator from 'src/components/shared/Separator'
import { useRouter } from 'next/router'
import { GROWTH_PLAN_SSO_PRICE_ID } from 'src/components/shared/PlanDetailsCard/Constants'
import TokenErrors from 'src/components/tokenErrors'
import { decodeEmail, formatDate, formatPrice, getSeats, nextChargeDate, optionsDate } from 'src/services/licenseKeyUtils'
import Link from 'components/shared/Link'

const OrderConfirmation = () => {
    const router = useRouter();
    const {
        customerEmail,
        customerId,
        subscriptionId,
        planId,
        subscriptionCreatedAt,
        invoiceAmount,
        invoiceAmountPaid,
        token: emailToken,
        tokenLifetimeInHours,
        email
    } = router.query;

    const seats = getSeats(invoiceAmount, planId);
    // decodeEmail email format
    const validCustomerEmail = decodeEmail(customerEmail) ? customerEmail : null;
    const validEmail = decodeEmail(email) ? email : null;

    const userComingFromCheckoutPage = validCustomerEmail && customerId && subscriptionId && planId && subscriptionCreatedAt && invoiceAmount && invoiceAmountPaid
    const userComingFromEmail = emailToken && tokenLifetimeInHours && validEmail
    const token = userComingFromEmail ? emailToken : `${customerId}-${subscriptionId}`;

    if (!userComingFromCheckoutPage && !userComingFromEmail) {
        return <TokenErrors />
    }
    return (
        <div className={css.container}>
            <div className={css.header}>
                <Link as="/" href="/">
                    <Img
                        alt="Strapi Logo"
                        src="/assets/strapi-logo-dark.svg"
                        width={152}
                        height={37}
                    />
                </Link>
            </div>
            <div>
                <Text theme="blue-900" className={css.title}>Thank you for your order!</Text>
                <Text theme="neutral-600" className={css.subtitle}>
                    {userComingFromCheckoutPage ? `Your receipt and license key are on the way to ${validCustomerEmail}` : `Your receipt has been sent to ${validEmail}`}
                </Text>
            </div>
            <div className={css.content}>
                <div className={css.content__container}>
                    <div className={css.orderSuccess}>
                        <Text theme="blue-900" className={css.orderSuccess__title}>License key details</Text>
                        <Text theme="blue-900" className={css.securityText}>
                            <p>For security reasons, you can access your license from this page only once.</p>
                            <p>Make sure you are ready to copy and save it in the next step. <b>This page will remain available for {tokenLifetimeInHours ? '12 hours' : '1 hour'}.</b> {userComingFromCheckoutPage ? 'After that, you can still access your license using the link in your confirmation email. The email link will remain valid for 12 hours.' : 'After that, the license will no longer be accessible, and you will need to contact support for assistance.'}</p>
                        </Text>
                    </div>
                    <Button
                        className={css.button}
                        theme="blue"
                        type="button"
                        href={`/get-license?token=${token}`}
                    >
                        Show license key
                    </Button>
                </div>
                {userComingFromCheckoutPage && (
                    <div className={css.orderSummary}>
                        <Text theme="neutral-500" className={css.orderSummary__title}>Order summary</Text>
                        <div className={css.orderSummary__details}>
                            <div>
                                <Text theme="blue-900" className={css.orderSummary__subtitle}>Growth plan {planId === GROWTH_PLAN_SSO_PRICE_ID && "+ SSO"}</Text>
                                <Text theme="neutral-500" size="smaller" className={css.orderSummary__price}>{seats} {seats > 1 ? 'seats' : 'seat'}</Text>
                                <Text theme="neutral-500" size="smaller" className={css.orderSummary__price}>Starts {formatDate(subscriptionCreatedAt, optionsDate)} </Text>
                            </div>
                            <Text theme="neutral-500" size="small">{formatPrice(invoiceAmount)}</Text>
                        </div>
                        <Separator />
                        <div className={css.orderSummary__details}>
                            <Text theme="blue-900" size="big" className={css.orderSummary__subtitle}>Today's total</Text>
                            <Text theme="blue-900" size="big">{formatPrice(invoiceAmountPaid)}</Text>
                        </div>
                        <Separator />
                        <div className={css.orderSummary__details}>
                            <div>
                                <Text theme="blue-900" size="small">Total billed monthly</Text>
                                <Text theme="grey-600" size="smaller">Next charge date {nextChargeDate(subscriptionCreatedAt, optionsDate)}</Text>
                            </div>
                            <Text theme="grey-600" size="small">{formatPrice(invoiceAmount)}</Text>
                        </div>
                    </div>
                )}
            </div>
        </div>)
}

export default OrderConfirmation;
