export const errorDetails = {
    'ERR_TOKEN_NOT_FOUND': {
        title: 'License is not available',
        message: 'Your license is currently being generated and is not ready at this time. Please check back in a few moments.',
        buttonText: 'Retry',
    },
    'ERR_TOKEN_EXPIRED': {
        title: 'Access expired',
        message: 'The delay to access your license through the browser has passed. To retrieve your license, please contact our support team for further assistance.',
        buttonText: 'Contact support team',
        email: 'support@strapi.io',
    },
    'ERR_NO_USAGE_LEFT': {
        title: 'License view limit has been reached',
        message: 'The license has been already viewed. For security reasons, it can only be accessed once. If you’ve lost your license, please contact our support team to request it again.',
        buttonText: 'Contact support team',
        email: 'support@strapi.io',
    },
    'DEFAULT': {
        title: 'An error occurred',
        message: 'Something went wrong on our end. Please try again later.',
    },
};